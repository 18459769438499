import React, { useState } from 'react';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import './App.css';

const PackingSlipGenerator = () => {
  const [excelData, setExcelData] = useState([]);
  const [printJobNumber, setPrintJobNumber] = useState('yes');
  const [pdfPreview, setPdfPreview] = useState(null);
  const [pdfInstance, setPdfInstance] = useState(null); // Store the jsPDF instance

  // Handle file upload
  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      const binaryStr = e.target.result;
      const workbook = XLSX.read(binaryStr, { type: 'binary' });
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const data = XLSX.utils.sheet_to_json(worksheet);
      setExcelData(data);
    };
    reader.readAsBinaryString(file);
  };

  // Generate PDF and create a preview
  const generatePDF = () => {
    const doc = new jsPDF();
    const leftMargin = 20;
    const rightMargin = 190;
    const descriptionWidth = 120;
    const quantityWidth = 40;

    excelData.forEach((rowData, index) => {
      if (index > 0) {
        doc.addPage();
      }

      doc.setFontSize(10);
      doc.text(`Row #: ${index + 1}`, leftMargin, 10);

      const currentDate = new Date().toLocaleDateString();
      const kitVersion = rowData['Kit Version'];

      doc.setFontSize(12);
      doc.text('Packing Slip', 105, 10, { align: 'center' });

      if (printJobNumber === 'yes') {
        doc.setFontSize(10);
        doc.text(`Job Number: ${kitVersion}`, rightMargin, 10, { align: 'right' });
      }

      doc.text(`Date: ${currentDate}`, rightMargin, 20, { align: 'right' });

      doc.setFontSize(10);
      let yPosition = 30;

      if (rowData['Location Name']) {
        doc.text(String(rowData['Location Name']), leftMargin, yPosition);
        yPosition += 5;
      }

      if (rowData['Attn']) {
        doc.text(`Attn: ${String(rowData['Attn'])}`, leftMargin, yPosition);
        yPosition += 5;
      }

      const addressLine = `${rowData['Address 1'] ? String(rowData['Address 1']) : ''}${rowData['Address 2'] ? ', ' + String(rowData['Address 2']) : ''}`;
      if (addressLine.trim()) {
        doc.text(addressLine, leftMargin, yPosition);
        yPosition += 5;
      }

      const cityLine = `${rowData['City'] ? String(rowData['City']) : ''}${rowData['State/Province'] ? ', ' + String(rowData['State/Province']) : ''} ${rowData['Country'] ? String(rowData['Country']) : ''}`;
      if (cityLine.trim()) {
        doc.text(cityLine, leftMargin, yPosition);
        yPosition += 5;
      }

      if (rowData['Zip']) {
        doc.text(String(rowData['Zip']), leftMargin, yPosition);
        yPosition += 5;
      }

      if (rowData['Phone']) {
        doc.text(String(rowData['Phone']), leftMargin, yPosition);
        yPosition += 5;
      }

      yPosition += 10;

      doc.setFillColor(0, 0, 0);
      doc.setTextColor(255, 255, 255);
      doc.rect(leftMargin, yPosition, descriptionWidth, 10, 'F');
      doc.rect(leftMargin + descriptionWidth, yPosition, quantityWidth, 10, 'F');
      doc.text('Description', leftMargin + descriptionWidth / 2, yPosition + 5, { align: 'center' });
      doc.text('Quantity', leftMargin + descriptionWidth + quantityWidth / 2, yPosition + 5, { align: 'center' });

      doc.setTextColor(0, 0, 0);

      const versionColumns = Object.keys(rowData).slice(Object.keys(rowData).indexOf('Kit Version') + 1);

      yPosition += 10;
      versionColumns.forEach((version) => {
        const quantity = rowData[version];

        if (!quantity || quantity === 0) return;

        let formattedQuantity;
        if (typeof quantity === 'number') {
          formattedQuantity = Number.isInteger(quantity) ? quantity.toString() : quantity.toFixed(2);
        } else {
          formattedQuantity = String(quantity);
        }

        doc.text(String(version), leftMargin + 5, yPosition + 5);
        doc.text(formattedQuantity, leftMargin + descriptionWidth + quantityWidth / 2, yPosition + 5, { align: 'center' });

        doc.rect(leftMargin, yPosition, descriptionWidth, 10);
        doc.rect(leftMargin + descriptionWidth, yPosition, quantityWidth, 10);

        yPosition += 10;
      });
    });

    // Set the preview and store the jsPDF instance
    const pdfDataUri = doc.output('datauristring');
    setPdfPreview(pdfDataUri);
    setPdfInstance(doc); // Store the jsPDF instance for later use
  };

  // Download the stored PDF instance
  const downloadPDF = () => {
    if (pdfInstance) {
      pdfInstance.save('professional_packing_slips.pdf');
    }
  };

  return (
    <div className="generator-container">
      <div className="file-input-wrapper">
        <input type="file" accept=".xlsx" onChange={handleFileUpload} />
      </div>
      <div className="dropdown-wrapper">
        <label htmlFor="printJobNumber">Print Job Number: </label>
        <select id="printJobNumber" onChange={(e) => setPrintJobNumber(e.target.value)}>
          <option value="yes">Yes</option>
          <option value="no">No</option>
        </select>
      </div>
      {excelData.length > 0 && (
        <button onClick={generatePDF}>Generate PDF</button>
      )}

      <div className="template-download">
        <h4>Need a template? Download it below:</h4>
        <a href="/template.xlsx" download="packing_slip_template.xlsx">
          <button>Download Template</button>
        </a>
      </div>

      {pdfPreview && (
        <div className="pdf-preview">
          <h3>PDF Preview</h3>
          <iframe src={pdfPreview} title="PDF Preview"></iframe>
          <div className="button-container">
            <button className="download-button" onClick={downloadPDF}>
              Download PDF
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default PackingSlipGenerator;
